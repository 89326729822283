<template>
    <login-form></login-form>
</template>

<script>

import LoginForm from '../../components/app/LoginForm'
export default {
    components: {
        'login-form': LoginForm
    }
}
</script>